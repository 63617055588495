.modal-entrance {
    animation: entranceAnim 0.4s linear 0s 1 normal none;
    @keyframes entranceAnim {
        0% {
            opacity: 0;
            transform: translateY(-250px);
        }

        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

.modal-exit {
    animation: exitAnim 1s linear 0s 1 normal none;
    @keyframes exitAnim {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }
}